import { Euler , Vector3 } from "three";

/*as THREE from 'three/build/three.module.js';*/
const PI_2 = Math.PI/2;
const xEast = -9;
const xWest= 9;
const zNorth = 12.4;
const zSouth = 0;
const yFloor = 2.29;
const yCeiling = 4.7;


class NavigationCore {
    constructor(camera) {
        this.camera = camera;
        this.rotateCameraXY = this.rotateCameraXY.bind(this);
        this.moveForward = this.moveForward.bind(this);
        this.moveRight = this.moveRight.bind(this);
        this.moveUp = this.moveUp.bind(this);
    }

    static rotateCameraXY2( camera,deltaX , deltaY ) {

        if (deltaX === 0 && deltaY === 0) return;

        let euler = new Euler( 0, 0, 0, 'YXZ' );

        euler.setFromQuaternion( camera.quaternion );

        euler.y += deltaY;
        euler.x += deltaX;

        euler.x = Math.max( - PI_2, Math.min( PI_2, euler.x ) );

        camera.quaternion.setFromEuler( euler );

    }

    rotateCameraXY( deltaX , deltaY ) {

        NavigationCore.rotateCameraXY2(this.camera,deltaX,deltaY)


    }

    static moveForward2( camera,distance ) {

        if (distance === 0) return;

        let vec = new Vector3();
        // move forward parallel to the xz-plane
        vec.setFromMatrixColumn( camera.matrix, 0 );
        vec.crossVectors( camera.up, vec );

        let vecTmp = camera.position.clone();
        vecTmp.addScaledVector(vec,distance);
        if (vecTmp.x > 8.5 || vecTmp.x < -8.3 || vecTmp.z > 11.5 || vecTmp.z < .64) {
            return;
        }

        camera.position.addScaledVector( vec, distance );

    };

    moveForward( distance ) {

        NavigationCore.moveForward2(this.camera,distance);

    };

    static moveRight2 ( camera,distance ) {

        if (distance === 0) return;

        let vec = new Vector3();

        vec.setFromMatrixColumn( camera.matrix, 0 );

        let vecTmp = camera.position.clone();
        vecTmp.addScaledVector(vec,distance);
        if (vecTmp.x > 8.5 || vecTmp.x < -8.3 || vecTmp.z > 11.5 || vecTmp.z < .64) {
            return;
        }

        camera.position.addScaledVector( vec, distance );

    };

    moveRight ( distance ) {

        NavigationCore.moveRight2(this.camera,distance);

    };

    static moveUp2 ( camera, distance ) {

        if (distance === 0) return;

        let vec = new Vector3();

        vec.setFromMatrixColumn( camera.matrix, 1 );

        let vecTmp = camera.position.clone();
        vecTmp.addScaledVector(vec,distance);
        if (vecTmp.y > 4.5 || vecTmp.y < 0.1 ) {
            return;
        }

        camera.position.addScaledVector( vec, distance );

    };

    moveUp ( distance ) {

        NavigationCore.moveUp2(this.camera,distance);

    };

}

export default NavigationCore;
