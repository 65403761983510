import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import ReactPlayer from "react-player";

import ReactGa from 'react-ga';

class AnnotationModal extends Component {
    //annotationURL;
    constructor(props) {
        super(props);

        this.state = {
            annotationURL: ''
        }
    }

    onShow = () => {
        // this.annotationURL = this.props.annotationURL;
        this.setState({
            // ...this.state,
            ...this.props.annotationData
        })

        //This is part of the Google Analytics Module
        // console.log("ReactGa", this.props.annotationData.paintingId);
        ReactGa.event({
            category: 'Annotation',
            action: `User clicked an annotation ${this.props.annotationData.paintingId}`,
        })


    }

    onSendEmail = () => {
        window.location = this.state.EnquireEmail;
    }

    gotoPage = () => {
        // window.location = this.state.ContactURL;
        window.open(this.state.ContactURL,'_blank');
    }

    shareEmail= () => {
        window.location = this.state.shareEmail;

    }

    render() {
        let buyNow = null;
        let sendTo = null;
        let pipe1 = null;

        let enquire =   <a href={this.state.EnquireEmail}  onClick={()=> this.onSendEmail()} >ENQUIRE</a> /*+ this.state.isSold === 'N' ?  `&nbsp;|&nbsp` : ''*/;

        if (this.state.isSold === 'N') {
            pipe1 =  <span>&nbsp;|&nbsp;</span>;
            buyNow = <a href={this.state.ContactURL} target="_blank" onClick={() => this.gotoPage()}>BUY NOW</a> ;
            sendTo = <a href={this.state.shareEmail} target="_blank" onClick={() => this.shareEmail()}>SEND TO</a>

        }

        return (

            <div className="annotation-modal">
                <Modal dialogClassName={"primaryModal modal-dialog annotation-modal-classname modal-dialog-scrollable"}
                       show={this.props.showAnnotationModal} onShow={() => this.onShow()}
                       onHide={this.props.hideAnnotationModal} size={this.props.annotationModalSize} centered={true}
                       backdrop="static">
                    <Modal.Header closeButton style={{border: 'none'}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="annotation-main-container-modal">
                                <div className="annotation-media-container">
                                    <div className="annotation-image-wrapper">
                                        {this.state.isAnnotationVideo ?
                                            <video width="100%" controls>
                                                <source src={this.state.videoURL} type="video/mp4"/>
                                                <source src={this.state.videoURL} type="video/ogg"/>
                                                Your browser does not support HTML video.
                                            </video> :
                                            <img
                                                src={this.state.imageUrl} alt="Annotation painting section"/>
                                        }
                                    </div>
                                </div>
                                <div className="annotation-text-container">
                                    <div className="annotation-header-text-container">
                                        <h2>{this.state.headerText}</h2>
                                    </div>
                                    <div className="annotation-body-text-container">
                                        <p>{this.state.bodyText}</p>
                                    </div>
                                </div>
                                <div className="annotation-contact-container">

                                    <h6>
                                        {enquire}{pipe1} {buyNow} {pipe1} {sendTo}
                                    </h6>

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            /* */
        )
    }


}

export default AnnotationModal;
