
import {ANNOTATION_VIDEO_DISPLAY,ANNOTATION_PARENT} from '../../globalConstants/GlobalConstants';

const ANNOTATION_CONTACT_TEXT = 'geraldine@kahn-gallery.com';

const AnnotationsDB = [
    {
        paintingId: 'W01P01',
        videoId: null,
        videoURL: null,
        headerText: 'Colibri',
        bodyText: `Lithograph on HAHNEMULHE 325 G HIGHT GLOSSY paper-unframed
32 x 32 cm / 12 x 12 inches
Limited edition of 20
£800 / $1000 / 900€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/colibriprint'
    },

    {
        paintingId: 'W01P02',
        videoId: null,
        videoURL: null,
        headerText: 'BO Super Green',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
120 x 240 cm / 47 x 95 inches
Original artwork
£17700 / $21600 / 20000€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/bosupergreen',
        displayHeight:80
    },

    {
        paintingId: 'W01P03',
        videoId: null,
        videoURL: null,
        headerText: 'Subway',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
180x60cm / 71 x 23 inches
Original artwork
£6400 / $7800 / 7300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/subway'
    },

    {
        paintingId: 'W01P04',
        videoId: null,
        videoURL: null,
        headerText: 'Him',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
150 x 150 cm / 59 x 59 inches
Original artwork
£16800 / $20500 / 18900€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/him1'
    },

    {
        paintingId: 'W01P05',
        videoId: null,
        videoURL: null,
        headerText: 'Avant l’autre',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
122x130cm / 51 x 48 inches
Original artwork
£11150 / $13600 / 12600€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/avantlautre'
    },

    {
        paintingId: 'W01P06',
        videoId: null,
        videoURL: null,
        headerText: 'Hunk Art',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
153x170cm / 60 x 66 inches
Original artwork
£18000 / $22000 / 20300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/hunkart'
    },

    {
        paintingId: 'W02P01',
        /* annotationScale: {x:.1,y:.1,z:1},
         annotationPos:{x:0,y:0,z:0},
         annotationRotation: {x:0,y:0,z:0},*/
        videoId: null,
        videoURL: null,
        headerText: 'One Day in Miami',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
120 x 120 cm / 47 x 47 inches
Original artwork
SOLD`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        isSold:'Y',
    },

    {
        paintingId: 'W02P02',
        videoId: null,
        videoURL: null,
        headerText: 'Saint Denis',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
89 x 146 cm / 35 x 57 inches
Original artwork
£8200 / $10000 / 9300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/saintdenis',
        displayHeight:85
    },

    {
        paintingId: 'W02P03',
        videoId: null,
        videoURL: null,
        headerText: 'Kars',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
80 x 80 cm / 31 x 31 inches
Original artwork
£5100 / $6200 / 5700€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/kars'
    },

    {
        paintingId: 'W02P04',
        videoId: null,
        videoURL: null,
        headerText: 'Vince',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
76x76cm / 30 x 30 inches
Original artwork
£5100 / $6200 / 5700€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/vince'
    },

    {
        paintingId: 'W02P05',
        videoId: null,
        videoURL: null,
        headerText: 'Station',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
80x80 cm / 31 x 31 inches
Original artwork
£5100 / $6200 / 5700€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/station'
    },

    {
        paintingId: 'W02P06',
        videoId: null,
        videoURL: null,
        headerText: 'Jimmy',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
130x28cm / 51 x 11 inches
Original artwork
£3600 / $4400 / 4100€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/jimmy'
    },

    {
        paintingId: 'W03P01',
        videoId: null,
        videoURL: null,
        headerText: 'Him Yellow',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
100x 100 cm / 39 x 39 inches
Original artwork
£6400 / $7800 / 7200€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/himyellow'
    },

    {
        paintingId: 'W03P02',
        videoId: null,
        videoURL: null,
        headerText: 'The Wall',
        bodyText: `Technique Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
62 x 150 cm / 24 x 59 inches
Original artwork
£5600 / $6800 / 6300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/thewall',
        displayHeight:75
    },

    {
        paintingId: 'W03P03',
        videoId: null,
        videoURL: null,
        headerText: 'Bushwick',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
100 x100 cm / 39 x 39 inches
Original artwork
£6400 / $7800 / 7200€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/bushwick'
    },

    {
        paintingId: 'W03P04',
        videoId: null,
        videoURL: null,
        headerText: 'Flamingo',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
146 x 89 cm / 57 x 35 inches
Original artwork
£8200 / $10000 / 9300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/flamingo',
        displayHeight:85
    },

    {
        paintingId: 'W03P05',
        videoId: null,
        videoURL: null,
        headerText: 'Foot Massage',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
116 x 89 cm / 45 x 35 inches
Original artwork
£6800 / $8300 / 7700€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/footmassage'
    },

    {
        paintingId: 'W04P01',
        videoId: null,
        videoURL: null,
        headerText: 'Fly Circle',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
160 cm diam. / 63 inches
Original artwork
£17600 / $21500 / 19900€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/fly-'
    },

    {
        paintingId: 'W04P02',
        videoId: null,
        videoURL: null,
        headerText: 'Samples 2',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
150 x 150 cm / 59 x 59 inches
Original artwork
£16000 / $19500 / 18000€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/samples2'
    },

    {
        paintingId: 'W04P03',
        videoId: null,
        videoURL: null,
        headerText: 'Alpine',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
100 x 81 cm / 39 x 32 inches
Original artwork
£10900 / $13400 / 12400€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/alpine',
        displayHeight:80
    },

    //todo : add sprite display type
    {
        paintingId: 'W04V04',
        videoId: 'W04V04',
        displayType: ANNOTATION_VIDEO_DISPLAY.ANNOTATION_VIDEO,
        videoURL: 'W04V04.mp4',
        headerText: 'Samples 1',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
150 x 150 cm / 59 x 59 inches
Original artwork
SOLD`,
        displayHeight:80,
        isSold:'Y',
        EnquireEmail: ANNOTATION_CONTACT_TEXT,

    },

    {
        paintingId: 'W04P05',
        videoId: null,
        videoURL: null,
        headerText: 'Chueca',
        bodyText: `Acrylic paint and collages on canvas, printed perspex bolted on top
100 x 100 cm / 39 x 39 inches
Original artwork
£6400 / $7800 / 7200€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/chueca'
    },

    {
        paintingId: 'W04V01',
        videoId: 'W04V01',
        displayType: ANNOTATION_VIDEO_DISPLAY.ANNOTATION_TEXT | ANNOTATION_VIDEO_DISPLAY.VIDEO_STANDALONE,
        videoURL: 'W04V01.mp4',
        headerText: 'Samples Recto Verso',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
140 x 140 cm / 55.11 x 55.11 inches
Original artwork
Price On Request`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/setof16samples',
        displayMesh:'N', // //todo : add this property to global variables
        displayHeight:90
    },

    {
        paintingId: 'W02T01',
        annotationScale: {x:.1,y:.1,z:1},
        annotationPos:{x:-8.6,y:2,z:2.8},
        annotationRotation: {x:0,y:0,z:0},
        annotationImageURL:null,
        spriteImageURL:`/assets/sprites/followIcon.svg`,
        imageURL:`/images/W02T01_SocialMediaAnnotation.png`,
        videoId: null,
        videoURL: null,
        headerText:  'FOLLOW FOR UPDATES',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
89 x 146 cm / 35 x 57 inches
Original artwork
£8200 / $10000 / 9300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/saintdenis',
        displayHeight:85,
        annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        socialMedia:'Y',
    },
    {
        paintingId: 'W04T01',
        annotationScale: {x:.1,y:.1,z:1},
        annotationPos:{x:5.6,y:1.8,z:12.086},
        annotationRotation: {x:0,y:0,z:0},
        spriteImageURL:`/assets/sprites/followIcon.svg`,
        imageURL:`/images/W02T01_SocialMediaAnnotation.png`,
        videoId: null,
        videoURL: null,
        headerText: 'FOLLOW FOR UPDATES',
        bodyText: `Acrylic paint and collages on canvas, photos printed on PMMA (Plexiglass) bolted on top
89 x 146 cm / 35 x 57 inches
Original artwork
£8200 / $10000 / 9300€`,
        EnquireEmail: ANNOTATION_CONTACT_TEXT,
        ContactURL: 'https://www.kahn-gallery.com/product-gregory-watin/saintdenis',
        displayHeight:85,
        annotationParent: ANNOTATION_PARENT.PARENT_NONE,
        socialMedia:'Y',
    },




];



export {AnnotationsDB, ANNOTATION_CONTACT_TEXT};
