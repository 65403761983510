import * as THREE from 'three';
export const CANVAS_ID = 'main_rendering_id';
export const ANNOTATION_LAYER_CHANEL = 1;
export const VIDEO_LAYER_CHANEL = 2;
export const DEFAULT_ANNOTATION_SCALE = new THREE.Vector3(.1,.1,1);
export const DEFAULT_ANNOTATION_POS = new THREE.Vector3();
export const ANNOTATION_PARENT = {
    PARENT_MESH:1,
    PARENT_NONE:2
}
export const DEFAULT_SPRITE_IMAGE_URL = '/assets/sprites/AnnotationsI.svg'

export const ANNOTATION_VIDEO_DISPLAY = {
    ANNOTATION_TEXT:1,
    ANNOTATION_VIDEO:2,
    VIDEO_STANDALONE:4,
}

export const MOBILE_TOOLTIP_TIMEOUT = 1500;
