import React, {Component} from 'react';
import {Viewer} from "./viewer"
import "./Viewer.css"
import CircleJoystick from "../joystick/CircleJoystick";
import FPSNavigationControl from '../navigation/fps-navigation-control'
import AnnotationContainer from '../annotations/AnnotationContainer'
import {ANNOTATION_LAYER_CHANEL} from '../../globalConstants/GlobalConstants'
import Hamburger from '../../icons/Hamburger.svg';
import VR from '../../icons/VR.svg';
import FullScreen from '../../icons/FullScreen.svg';
import QuestionMark from '../../icons/QuestionMark.svg';
import ResetArrows from '../../icons/ResetArrows.svg';
import CloseFullScreen from '../../icons/CloseFullScreen.svg';
import ToggleAnnotation from '../../icons/ToggleAnnotaion.svg';

import V21DropdownMenu from "../menu/V21DropdownMenu";
import V21Navbar from "../menu/V21NavBar";
import V21NavItem from "../menu/V21NavItem";
import HelpModal from '../modals/HelpModal'
import LoadingProgressModal from "../modals/LoadingProgressModal";
import AnnotationModal from "../annotations/AnnotationModal";
import AnnotationDataController from "../annotations/AnnotationDataController";
import MediaController from '../annotations/MediaController'
import VideoContainer from "../annotations/VideoContainer";
import SocialMediaAnnotationModal from "../annotations/SocialMediaAnnotationModal";


class ThreeGLTFLoader extends Component {
    viewerCamera = null;
    annotationData = null;
    pausePreventDefault = false;
    mediaController;
    videoContainer;
    constructor(props) {
        super(props);
        this.mount =  React.createRef();
        this.joystickUpdateLoop = null;
        this.setJoystickUpdateLoop = this.setJoystickUpdateLoop.bind(this);
        this.fullScreenChange = this.fullScreenChange.bind(this);
        this.viewer = null;
        this.fpsController = null;
        this.annotationContainer = null;

        this.state = {
            closeNavItem: false,
            openMenu: false,
            showHelp: false,
            loadingProgress: 0,
            showLoadingProgress: true,
            fullScreen: false,
            showAnnotation: true,
            showAnnotationModal: false,
            SocialMediaAnnotationModal:false,


        };
    }

    toggleAnnotation = () => {
        if (this.state.showAnnotation) {
            this.annotationContainer.closeActiveAnnotation();
            this.viewerCamera.layers.disable(ANNOTATION_LAYER_CHANEL);

        } else {
            this.viewerCamera.layers.enable(ANNOTATION_LAYER_CHANEL);
        }

        this.setState({
            ...this.state,
            showAnnotation: !this.state.showAnnotation,
            closeNavItem: true,
            openMenu: false
        })
    }

    loadingTimeoutFunc = () => {

        //the help modal will open so pause preventdefault to scroll
        this.pausePreventDefault = true;

        this.setState({
            ...this.state,
            showLoadingProgress: false,
            showHelp:true

        });
    }


    modelLoadingProgress = (percent) => {
        this.setState({
            ...this.state,
            loadingProgress: percent
        })

        if (percent >= 100) {
            setTimeout(this.loadingTimeoutFunc, 10);
        }
    }

    componentDidMount() {
        this.viewer = new Viewer(this.mount.current, 'N', {useGUI:'N'});
        this.viewer.onLoadProgress = this.modelLoadingProgress;
        // this.viewer.load(process.env.PUBLIC_URL + '/models/Gallery_box_032.glb', '/');
        // this.viewer.load(process.env.PUBLIC_URL + '/models/objectWithGlassAndProp04_add_glass_mat.glb', '/');
        /* this.viewer.load(process.env.PUBLIC_URL + '/models/KhanGallery_029_for_mobile.glb', (scene, camera, css3dScene) => {
             this.viewerCamera = camera;
             this.annotationContainer = new AnnotationContainer(scene, camera, css3dScene,this.showAnnotationModal);
             this.viewer.addAnnotations(this.annotationContainer);
             this.viewer.setJoystickUpdateLoop(this.joystickUpdateLoop);
             this.fpsController = new FPSNavigationControl(this.mount.current);
             this.viewer.setFPSNavigation(this.fpsController.update);


         });*/

        this.viewer.load(process.env.PUBLIC_URL + '/models/KhanGallery_036.glb').then((obj) => {

            this.viewerCamera = obj.camera;
            //todo  if this.showAnnotationModal = null then we need to set useCss3Renderer  to 'Y' in Viewr class
            this.annotationContainer = new AnnotationContainer(obj.scene, obj.camera, obj.css3dScene,this.showAnnotationModal);
            // this.annotationContainer = new AnnotationContainer(obj.scene, obj.camera, obj.css3dScene);
            this.videoContainer = new VideoContainer(obj.scene, obj.camera, obj.css3dScene);
            this.mediaController = new MediaController(this.annotationContainer,this.videoContainer);
            this.viewer.addMedia( this.mediaController);
            this.viewer.setJoystickUpdateLoop(this.joystickUpdateLoop);
            this.fpsController = new FPSNavigationControl(this.mount.current);
            this.viewer.setFPSNavigation(this.fpsController.update);
        });

        document.addEventListener("fullscreenchange", this.fullScreenChange);

        /* Firefox */
        document.addEventListener("mozfullscreenchange", this.fullScreenChange);

        /* Chrome, Safari and Opera */
        document.addEventListener("webkitfullscreenchange", this.fullScreenChange);

        /* IE / Edge */
        document.addEventListener("msfullscreenchange", this.fullScreenChange);
        // let {scene ,camera} = this.viewer.getSceneAndCamera();


        if (window.isMobile) {
            document.addEventListener('touchmove', this.documentTouchMove, {passive: false});
        }
        /* alert(window.screen.orientation.type);
        if (navigator.userAgent.match(/Android/i)){
             alert("is mobile");
            window.screen.orientation.lock("landscape-primary");
        }*/
    }

    documentTouchMove = (evt) => {
        //used to allow scrolling in modal for mobile
        if (!this.pausePreventDefault) {
            evt.preventDefault();
        }

    }

    fullScreenChange() {
        if (
            document.fullscreenElement || /* Standard syntax */
            document.webkitFullscreenElement || /* Chrome, Safari and Opera syntax */
            document.mozFullScreenElement ||/* Firefox syntax */
            document.msFullscreenElement /* IE/Edge syntax */
        ) {


            this.setState({
                ...this.state,
                fullScreen: true
            })
        } else {
            this.setState({
                ...this.state,
                fullScreen: false
            })
        }
    }

    resizeToFullScreen = () => {
        // const elem = this.mount.current;
        // const elem = document.getElementById('root');

        if (this.state.fullScreen) {

            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        } else
        {
            if (document.body.requestFullscreen) {
                document.body.requestFullscreen();
            } else if (document.body.mozRequestFullScreen) { /* Firefox */
                document.body.mozRequestFullScreen();
            } else if (document.body.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                document.body.webkitRequestFullscreen();
            } else if (document.body.msRequestFullscreen) { /* IE/Edge */
                document.body.msRequestFullscreen();
            }
        }
        this.setState({
            ...this.state,
            fullScreen: !this.state.fullScreen
        })

    }

    setJoystickUpdateLoop(call) {
        this.joystickUpdateLoop = call;

    }

    delegateCloseNavItem = (evt) => {
        this.setState({
                ...this.state,
                closeNavItem: true

            }
        )
    }

    setCloseMenuToFalse = () => {
        this.setState({
                ...this.state,
                closeNavItem: false
            }
        )
    }

    onClickVR = (evt) => {

        //alert("vr was clicked");

        this.setState(
            {
                ...this.state,
                closeNavItem: true,
                openMenu: false
            }
        )
    }

    onClickFullScreen = (evt) => {

        this.resizeToFullScreen();

        this.setState(
            {
                ...this.state,
                closeNavItem: true,
                openMenu: false
            }
        )
    }

    onClickQuestionMark = (evt) => {
        this.pausePreventDefault = true;
        this.setState(
            {
                ...this.state,
                closeNavItem: true,
                openMenu: false,
                showHelp: true
            }
        )
    }

    onClickResetArrows = (evt) => {

        this.viewer.restoreCamera();
        this.setState(
            {
                ...this.state,
                closeNavItem: true,
                openMenu: false
            }
        )
    }

    onClickHamburger = (evt) => {
        this.setState(
            {
                ...this.state,
                openMenu: !this.state.openMenu
            }
        )
    }

    closeHelp = (evt) => {
        this.pausePreventDefault = false;
        this.setState(
            {
                ...this.state,
                showHelp: false
            }
        )
    }

    showAnnotationModal = (annotationId) => {
        this.pausePreventDefault = true;
        this.annotationData = AnnotationDataController.getAnnotationDataById(annotationId);
        if (this.annotationData) {
            if (this.annotationData.socialMedia === 'N') {
                this.setState({
                    ...this.state,
                    showAnnotationModal: true,
                    closeNavItem: true,
                    openMenu: false

                })
            }
            else {
                this.setState({
                    ...this.state,
                    closeNavItem: true,
                    openMenu: false,
                    SocialMediaAnnotationModal:true,
                })

            }

        }
    }

    hideAnnotationModal = () => {
        this.pausePreventDefault = false;
        this.setState({
            ...this.state,
            showAnnotationModal:false,
            SocialMediaAnnotationModal:false,
        })
    }

    render() {

        if (window.isMobile) {

            return (
                <div>
                    <video id="displayVideo" style={{visibility:'hidden'}}>
                        <source src={process.env.PUBLIC_URL + `/videos/W04V01.mp4`} type="video/mp4"/>
                        <source src={process.env.PUBLIC_URL + `/videos/W04V01.mp4`}  type="video/ogg"/>
                    </video>
                    <div id="GLTFViewer" className="viewer" ref={this.mount }>

                        {this.state.showLoadingProgress ? <LoadingProgressModal progress={this.state.loadingProgress}
                                                                                showModal={this.state.showLoadingProgress}/> : null}
                        <div className="produced-by-v21artspace">V21 ARTSPACE</div>
                        <HelpModal showHelp={this.state.showHelp} closefunc={this.closeHelp} modelType="sm"/>
                        <AnnotationModal showAnnotationModal={this.state.showAnnotationModal} hideAnnotationModal={this.hideAnnotationModal} annotationModalSize="md"  annotationData={  this.annotationData}  />
                        <SocialMediaAnnotationModal showAnnotationModal={this.state.SocialMediaAnnotationModal} hideAnnotationModal={this.hideAnnotationModal} annotationModalSize="md"  annotationData={  this.annotationData}  />

                        {this.state.showLoadingProgress ? null :
                            <div>
                                <div>

                                    <V21Navbar>

                                        <V21NavItem icon={Hamburger} tooltip_type="hamburger" closeMenu={this.state.closeNavItem}
                                                    setCloseMenuToFalse={this.setCloseMenuToFalse}>
                                            <V21DropdownMenu  isfullScreen={this.state.fullScreen} toggleAnnotation={this.state.showAnnotation ? "toggleAnnotationHide" : "toggleAnnotationShow" } onClick={this.delegateCloseNavItem}
                                                              onClickVR={this.onClickVR}
                                                              onClickFullScreen={this.onClickFullScreen}
                                                              onClickQuestionMark={this.onClickQuestionMark}
                                                              onClickToggleAnnotation={this.toggleAnnotation}
                                                              onClickResetArrows={this.onClickResetArrows}/>

                                        </V21NavItem>
                                    </V21Navbar>
                                </div>

                            </div>
                        }
                    </div>
                    <CircleJoystick update={this.setJoystickUpdateLoop} movingEventDispatchDelay={0}
                                    hide={this.state.showLoadingProgress}/>

                </div>
            )
        }
        return (
            <div>
                <video id="displayVideo" style={{display:'none'}}>
                    <source src={process.env.PUBLIC_URL + `/videos/W04V01.mp4`} type="video/mp4"/>
                    <source src={process.env.PUBLIC_URL + `/videos/W04V01.mp4`}  type="video/ogg"/>
                </video>
                <div  id="GLTFViewer" className="viewer" ref={this.mount}>

                    {this.state.showLoadingProgress ? <LoadingProgressModal progress={this.state.loadingProgress}
                                                                            showModal={this.state.showLoadingProgress}/> : null}



                    <HelpModal showHelp={this.state.showHelp} closefunc={this.closeHelp} modelType="lg"/>
                    <AnnotationModal showAnnotationModal={this.state.showAnnotationModal} hideAnnotationModal={this.hideAnnotationModal} annotationModalSize="md"  annotationData={  this.annotationData}  />
                    <SocialMediaAnnotationModal showAnnotationModal={this.state.SocialMediaAnnotationModal} hideAnnotationModal={this.hideAnnotationModal} annotationModalSize="md"  annotationData={  this.annotationData}  />

                    {this.state.showLoadingProgress ? null :
                        <div>
                            <V21Navbar>
                                <V21NavItem icon={QuestionMark} tooltip_type="help" onClick={this.onClickQuestionMark}/>
                                <V21NavItem icon={ToggleAnnotation}
                                            tooltip_type={this.state.showAnnotation ?  "toggleAnnotationHide" :"toggleAnnotationShow" }
                                            onClick={this.toggleAnnotation}/>
                            </V21Navbar>
                            <V21Navbar alignRightLeft="left">
                                <V21NavItem
                                    icon={this.state.fullScreen ? CloseFullScreen :FullScreen}
                                    tooltip_type={this.state.fullScreen ? "wscreen" : "fullscreen"}
                                    onClick={this.onClickFullScreen}/>
                                <V21NavItem icon={ResetArrows} tooltip_type="resetview" onClick={this.onClickResetArrows}/>
                            </V21Navbar>
                        </div>
                    }
                    <CircleJoystick update={this.setJoystickUpdateLoop} movingEventDispatchDelay={0}
                                    hide={this.state.showLoadingProgress}/>
                    <div className="produced-by-v21artspace">V21 ARTSPACE</div>
                </div>


            </div>
        )
    }
}

export default ThreeGLTFLoader;
